<template>
    <div class="m_foot flex_col flex_align_start">
        <div class="logo_wrap flex_align_center flex_justify_between">
            <img class="logo" src="@/assets/img_pc/foot_tag_1.png" alt="">
            <img class="tag" src="@/assets/img_pc/foot_tag_2.png" alt="">
        </div>
        <div class="nav_wrap HurmeGeometricSans3_Bold flex_col flex_align_start">
            <div class="nav_item click_cursor" v-for="(item, index) of navList" :key="index"
                v-if="item.path !== curPageName" @click="gotoPage(item.path)">
                {{item.text}}</div>
        </div>
        <h2 class="corporate_name">深圳市乐度数码科技有限公司</h2>
        <div class="keep_on_record">Copyright © 2021.乐度数码 All rights reserved. <a class="click_cursor"
                href="https://beian.miit.gov.cn/">粤ICP备2021154848号</a> </div>
        <a class="gongan flex_align_center"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902003110">
            <img class="gongan_tag" src="@/assets/gongan.png" alt="">
            <span>粤公网安备 44030902003110号</span>
        </a>
        <div class="third_party flex_align_center">
            <a href="https://mall.jd.com/index-1000330263.html?from=pc" target="_blank">
                <img src="@/assets/JD.png" alt="">
            </a>
            <a href="https://ledoyy.tmall.com/?spm=a220o.1000855.w5001-23393436697.6.59eb2ac3Nnr0fX&scene=taobao_shop" target="_blank">
                <img src="@/assets/TM.png" alt="">
            </a>
        </div>
        <div class="wx_wrap flex_col flex_align_center">
            <img src="@/assets/qr_code.png" alt="">
            <span class="text_center">微信公众号</span>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data() {
            return {
                navList: [
                    {
                        path: 'm-home',
                        text: 'HOME'
                    }, {
                        path: 'm-products',
                        text: 'Shadow series'
                    }, {
                        path: 'm-ategories',
                        text: 'All series'
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'curPageName'
            ])
        },
        methods: {
            gotoPage(path) {
                this.$router.push({
                    name: path
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .m_foot {
        width: 100%;
        position: relative;
        color: #111111;
        background-color: #00FF00;
        padding: 60px 16px;
        .logo_wrap {
            width: 100%;
            border-bottom: 1px solid #111111;
            padding-bottom: 50px;
            .logo {
                width: 130px;
                height: 40px;
            }
            .tag {
                width: 324px;
                height: 27px;
            }
        }
        .nav_wrap {
            padding: 70px 0 60px;
            .nav_item {
                line-height: 38px;
                font-size: 32px;
                margin-top: 30px;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .corporate_name, .keep_on_record, .gongan {
            line-height: 26px;
            font-size: 20px;
        }
        .corporate_name, .keep_on_record {
            margin-bottom: 24px;
        }
        .gongan_tag {
            width: 30px;
            height: 30px;
        }
        .third_party {
            position: absolute;
            right: 30px;
            top: 370px;
            img {
                width: 30px;
                height: 30px;

            }
            a {
                margin-left: 38px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .wx_wrap {
            position: absolute;
            right: 15px;
            top: 200px;
            width: 90px;
            img {
                width: 90px;
                height: 88px;
            }
            span {
                width: 90px;
                line-height: 26px;
                font-size: 16px;
                background-color: rgba(120, 120, 120, 0.18);
            }
        }
    }
</style>