<template>
    <div class="to_top_tag" ref="toTopTag">
        <img class="click_cursor w_h_100" src="@/assets/img_mobile/go_top.png" @click="handleToTop" alt="">
    </div>
</template>

<script>
    export default {
        name: 'Foot',
        props: {
            text: {
                type: String,
                default: 'HOME'
            }
        },
        data() {
            return {
                clientHeight: 0
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.clientHeight = window.innerHeight || document.documentElement.clientHeight
                document.addEventListener('scroll', this.toTopTag)
            })

        },
        destroyed() {
            document.removeEventListener('scroll', this.toTopTag)
        },
        methods: {
            handleToTop() {
                window.scrollTo(0, 0);
            },
            toTopTag() {
                let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
                if (scrollTop >= this.clientHeight) {
                    this.$refs.toTopTag.style.display = 'block'
                } else {
                    this.$refs.toTopTag.style.display = 'none'
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .to_top_tag {
        position: fixed;
        z-index: 9;
        right: 30px;
        bottom: 120px;
        display: none;
        width: 64px;
        height: 69px;
    }
</style>