<template>
    <div class="m_home_page">
        <m-nav-bar>
            <img class="logo" src="@/assets/img_mobile/logo.png" alt="">
        </m-nav-bar>
        <div class="rotation_wrap">
            <swiper :options="swiperOption" ref="rotationSwiper" @slideChangeTransitionStart="slideChangeStart"
                class="rotation_content w_h_100">
                <swiper-slide class="rotation_item">
                    <img :src="rotationList[0]" class="w_h_100" alt="">
                    <div class="text_wrap one flex_col flex_align_start">
                        <img src="@/assets/img_mobile/rotation_text_1_1.png" alt="">
                        <img src="@/assets/img_mobile/rotation_text_1_2.png" alt="">
                    </div>
                </swiper-slide>
                <swiper-slide class="rotation_item">
                    <img :src="rotationList[1]" class="w_h_100" alt="">
                    <!-- <div class="text_wrap two">
                        <img src="@/assets/img_mobile/rotation_text_2_1.png" alt="">
                    </div> -->
                    <div class="text_wrap three flex_col flex_align_start">
                        <img src="@/assets/img_mobile/rotation_text_3_1.png" alt="">
                        <img src="@/assets/img_mobile/rotation_text_3_2.png" alt="">
                    </div>
                </swiper-slide>
                <swiper-slide class="rotation_item">
                    <img :src="rotationList[2]" class="w_h_100" alt="">
                    <div class="text_wrap three flex_col flex_align_start">
                        <img src="@/assets/img_mobile/rotation_text_3_1.png" alt="">
                        <img src="@/assets/img_mobile/rotation_text_3_2.png" alt="">
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-pagination flex_justify_center"></div>
        </div>
        <div class="idea_wrap flex_col">
            <img class="title_tag" src="@/assets/img_mobile/tag_01.png" alt="">
            <span class="title_text HurmeGeometricSans3_SemiBold">Interpretation</br>of brand concept</span>
            <div class="flex_align_center">
                <img class="home_idea"
                    src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_idea.png"
                    alt="">
                <img class="home_idea_tag" src="@/assets/img_mobile/tag_02.png" alt="">
            </div>
            <p class="idea_content">
                乐毒(LEDO)矢志追求高品质听音效果，致力于设计出让人上瘾，疗伤奋进的优质音响数码设备。凭借对完美音质的深度理解，对精湛工艺的坚持传承，对视觉美感的享受统一，以及对高精技术研发的执着追求，打造一个符合时代潮流兼具性能与体验的音响数码潮酷品牌，沉浸音乐，<span>唤醒激情与灵魂。</span>
            </p>
        </div>
        <div class="more_wrap flex_align_end flex_justify_between">
            <img class="tag" src="@/assets/img_mobile/tag_03.png" alt="">
            <div class="more_tag_wrap flex_align_center">
                <span>探索更多品牌产品</span>
                <img class="more_tag" src="@/assets/img_mobile/tag_04.png" alt="">
            </div>
        </div>
        <div class="new_wrap">
            <img class="new_title" src="@/assets/img_mobile/tag_05.png" alt="">
            <video controls :src="$baseCosBaseUrl+'ledoVideo.mp4'" :poster="$baseCosBaseUrl+'poster.png'"></video>
        </div>
        <m-scroll-bar></m-scroll-bar>
        <div class="wrapper home_img_1_wrap"
            style="background-image: url('https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_2_1.jpg')">
            <a :href="productsData[1] && productsData[1].href" class="img_2_wrap" target="_blank">
                <!-- <img class="w_h_100"
                    src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_2_2.png"
                    alt="">
                <div class="price HurmeGeometricSans3_Bold"><span>￥</span> {{productsData[1] && productsData[1].price}}
                </div> -->
                <div class="img_wrap">
                    <img class="w_h_100"
                    src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_2_2.png"
                    alt="">
                    <div class="price HurmeGeometricSans3_Bold"><span>￥</span> {{productsData[1] && productsData[1].price}}</div>
                </div>
            </a>
        </div>
        <div class="wrapper home_img_2_wrap flex_col flex_align_center"
            style="background-image: url('https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_1_1.png')">
            <a :href="productsData[0] && productsData[0].href" target="_blank">
                <img class="img_2"
                    src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_1_2.png"
                    alt="">
            </a>
            <img class="tag" src="@/assets/img_mobile/tag_08.png" alt="">
            <div class="price HurmeGeometricSans3_SemiBold">￥{{productsData[0] && productsData[0].price}}</div>
        </div>
        <div class="wrapper home_img_3_wrap"
            style="background-image: url('https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_3_1.jpg')">
            <a :href="productsData[2] && productsData[2].href" class="img_2_wrap" target="_blank">
                <div class="img_wrap">
                    <img class="w_h_100" src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_3_2.png" alt="">
                    <div class="price HurmeGeometricSans3_Bold"><span>￥</span> {{productsData[2] && productsData[2].price}}
                    </div>
                </div>
            </a>
        </div>
        <div class="wrapper home_img_4_wrap flex_col flex_align_center"
            style="background-image: url('https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_4_1.png')">
            <a :href="productsData[3] && productsData[3].href" target="_blank">
                <img class="img_2"
                    src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_img_4_2.png"
                    alt="">
            </a>
            <img class="tag" src="@/assets/img_mobile/tag_08.png" alt="">
            <span class="price HurmeGeometricSans3_SemiBold">￥{{productsData[3] && productsData[3].price}}</span>
        </div>
        <m-scroll-bar></m-scroll-bar>
        <m-foot></m-foot>
        <m-to-top></m-to-top>
    </div>
</template>

<script>
    import mNavBar from "@/components/mobile/m-nav-bar.vue";
    import mScrollBar from "@/components/mobile/m-scroll-bar.vue"
    import mFoot from "@/components/mobile/m-foot.vue"
    import mToTop from "@/components/mobile/m-to-top.vue"

    import { mapState } from 'vuex'

    export default {
        name: 'm-home',
        data() {
            return {
                swiperOption: {
                    direction: 'horizontal',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                },
                rotationList: [
                    'https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_rotation_01.jpg',
                    'https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_rotation_02.jpg',
                    'https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/home_rotation_03.jpg'
                ],
                activedIndex: 0,
            }
        },
        computed: {
            ...mapState([
                'productsData'
            ]),
            rotationSwiper() {
                return this.$refs.rotationSwiper.swiper
            }
        },
        methods: {
            slideChangeStart(e) {
                this.activedIndex = this.rotationSwiper.activeIndex
            }
        },
        components: {
            mNavBar,
            mScrollBar,
            mFoot,
            mToTop
        }
    }
</script>

<style lang="scss" scoped>
    .m_home_page {
        min-height: 100vh;
        color: #EEEDE8;
        background-color: #111111;

        .logo {
            width: 76px;
            height: 23px;
        }

        .rotation_wrap {
            position: relative;
            width: 100%;
            height: 1334px;

            .swiper-pagination {
                left: 0;
                bottom: 24px;
                width: 100%;

                /deep/.swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.5);
                    margin-left: 10px;

                    &.swiper-pagination-bullet-active {
                        width: 20px;
                        height: 10px;
                        border-radius: 5px;
                        background-color: #00FF00;
                    }
                }
            }

            .text_wrap {
                position: absolute;
                z-index: 1;

                img {
                    transition: transform 0.3s ease;
                }

                &.one {
                    left: 186px;
                    top: 207px;

                    img {
                        &:nth-child(1) {
                            width: 435px;
                            height: 30px;
                            margin-bottom: 60px;
                        }

                        &:nth-child(2) {
                            width: 314px;
                            height: 59px;
                        }
                    }
                }

                &.two {
                    left: 50%;
                    top: 148px;
                    transform: translateX(-50%);

                    img {
                        &:nth-child(1) {
                            width: 662px;
                            height: 46px;
                        }
                    }
                }

                &.three {
                    left: 102px;
                    top: 981px;

                    img {
                        &:nth-child(1) {
                            width: 485px;
                            height: 79px;
                            margin-bottom: 30px;
                        }

                        &:nth-child(2) {
                            width: 314px;
                            height: 59px;
                        }
                    }
                }
            }
        }

        .idea_wrap {
            padding: 120px 30px 90px;

            .title_tag {
                width: 237px;
                height: 45px;
            }

            .title_text {
                line-height: 24px;
                font-size: 18px;
                margin: 18px 0 90px;
            }

            .home_idea {
                width: 628px;
                height: 372px;
            }

            .home_idea_tag {
                width: 48px;
                height: 372px;
                margin-left: auto;
            }

            .idea_content {
                line-height: 48px;
                font-size: 20px;
                text-indent: 2em;
                margin-top: 50px;

                span {
                    color: #04C104;
                }
            }
        }

        .more_wrap {
            width: 690px;
            border-bottom: 1px solid #5D5D5D;
            ;
            padding-bottom: 25px;
            margin: 0 auto;

            .tag {
                width: 63px;
                height: 17px;
                margin-bottom: 8px;
            }

            span {
                font-size: 26px;
                color: #fff;
            }

            .more_tag {
                width: 53px;
                height: 52px;
                margin-left: 24px;
            }
        }

        .new_wrap {
            padding: 190px 30px 120px;

            .new_title {
                width: 100%;
                height: 111px;
                margin-bottom: 90px;
            }

            video {
                width: 100%;
            }
        }

        .wrapper {
            position: relative;
            width: 100%;
            height: 1200px;
            background-size: 100% 100%;
        }

        .home_img_1_wrap {
            .img_2_wrap {
                .img_wrap {
                    position: absolute;
                    right: 54px;
                    bottom: 167px;
                    z-index: 1;
                    width: 276px;
                    height: 352px;
                    &::before {
                        position: absolute;
                        left: 1px;
                        right: 1px;
                        top: 0px;
                        bottom: 0px;
                        border-radius: 20px;
                        z-index: -1;
                        background-color: rgba(255, 255, 255, 0.1);
                        /* filter: blur(5px); */
                        backdrop-filter: blur(40px);
                        content: '';
                    }
                    .price {
                        position: absolute;
                        right: 12px;
                        bottom: 70px;
                        line-height: 40px;
                        font-size: 28px;
                        color: #00FF00;
                        letter-spacing: 1px;

                        span {
                            font-size: 18px;
                            margin-right: -10px;
                        }
                    }
                }



            }
        }

        .home_img_2_wrap {
            padding-top: 214px;

            .img_2 {
                width: 484px;
                height: 774px;
            }

            .tag {
                width: 484px;
                height: 33px;
            }

            .price {
                line-height: 37px;
                font-size: 28px;
                color: #FFFFFF;
                margin-top: 80px;
            }
        }

        .home_img_3_wrap {
            .img_2_wrap {
                .img_wrap {
                    position: absolute;
                    right: 30px;
                    bottom: 141px;
                    z-index: 1;
                    width: 268px;
                    height: 344px;

                    &::before {
                        position: absolute;
                        left: 4px;
                        right: 4px;
                        top: 2px;
                        bottom: 2px;
                        border-radius: 20px;
                        z-index: -1;
                        background-color: rgba(255, 255, 255, 0.15);
                        filter: blur(5px);
                        content: '';
                    }

                    .price {
                        position: absolute;
                        left: 16px;
                        bottom: 70px;
                        line-height: 40px;
                        font-size: 28px;
                        color: #00FF00;
                        letter-spacing: 1px;

                        span {
                            font-size: 18px;
                            margin-right: -10px;
                        }
                    }
                }
            }
        }

        .home_img_4_wrap {
            padding-top: 212px;

            .img_2 {
                width: 484px;
                height: 774px;
            }

            .tag {
                width: 484px;
                height: 33px;
            }

            .price {
                line-height: 37px;
                font-size: 28px;
                color: #2B2B2B;
                margin-top: 80px;
            }
        }
    }
</style>