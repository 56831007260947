<template>
    <div class="custom_nav_bar HurmeGeometricSans3_SemiBold">
        <div class="wrapper w_h_100  flex_align_center flex_justify_between">
            <img class="go_back click_cursor" v-show="showBack" src="@/assets/img_pc/go_back.png" @click="handleBack"
                alt="">

            <div class="menu_wrap">
                <img class="menu click_cursor absolute_l_center" src="@/assets/img_pc/menu.png" @click="showMenu = true"
                    alt="">
                <div class="mask fixed_r_t flex_center_center" :class="type=='light'?'light':''">
                    <span>More</span>
                </div>
            </div>
        </div>
        <div class="warpper fixed_l_t " :class="showMenu?'active':''">
            <img src="@/assets/img_pc/close.png" class="close click_cursor" @click="showMenu = false" alt="">
            <div class="item_wrap  flex_col flex_align_start">
                <div class="item click_cursor flex_align_center" @click="gotoProducts(`product-${item.id}`)"
                    v-for="(item, index) of productsData" :key="index">
                    <span class="en">{{item.en}}</span>
                    <span class="cn SourceHanSansCN_Medium">{{item.name}}</span>
                    <img class="icon" :style="{transform: `translate3d(${movement.x}px,${movement.y}px,0)`}" :src="item.productIcon"
                        alt="">
                </div>
            </div>
            <div class="all_series click_cursor" @click="gotoAategories">All series</div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex'

    export default {
        name: 'CustomHead',
        props: {
            showBack: {
                type: Boolean,
                default: true
            },
            type: {
                type: String,
                default: 'dark'
            }
        },
        data() {
            return {
                showMenu: false,
                movement: {
                    x: 0,
                    y: 0
                }
            }
        },
        computed: {
            ...mapState([
                'curPageName',
                'productsData'
            ])
        },
        mounted() {
            document.addEventListener("mousemove", this.mouseMove)
        },
        watch: {
            // showMenu: {
            //     handler(newVal, oldVal) {
            //         if (newVal) {
            //             document.addEventListener("mousemove", this.mouseMove)
            //         } else {
            //             // document.removeEventListener("mousemove", this.mouseMove)
            //         }
            //     },
            //     immediate: true
            // }
        },
        methods: {
            gotoProducts(id) {
                if (this.curPageName == 'products') {
                    let scrollHeight = document.getElementById(id).offsetTop
                    window.scrollTo(0, scrollHeight)
                } else {
                    this.$router.push({
                        name: 'products',
                        params: {
                            id
                        }
                    })
                }
                this.showMenu = false
            },
            gotoAategories() {
                if (this.curPageName != 'ategories') this.$router.push('/ategories')
                this.showMenu = false
            },
            handleBack() {
                this.$router.go(-1)
                this.showMenu = false
            },
            mouseMove(e) {
                this.movement.x = e.clientX;
                this.movement.y = e.clientY;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/style/mixin.scss";

    .custom_nav_bar {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: formatPX_1920(88px);

        .go_back {
            width: formatPX_1920(23px);
            height: formatPX_1920(40px);
            margin-left: formatPX_1920(120px);
        }

        .logo {
            width: formatPX_1920(128px);
            height: formatPX_1920(38px);
        }

        .menu_wrap {
            position: fixed;
            right: 0;
            top: 0;
            width: formatPX_1920(168px);
            height: formatPX_1920(88px);

            &:hover {
                .mask {
                    display: flex;
                }
            }

            .menu {
                top: formatPX_1920(32px);
                z-index: 9;
                width: formatPX_1920(38px);
                height: formatPX_1920(25px);
            }

            .mask {
                display: none;
                width: formatPX_1920(168px);
                height: 100vh;
                border-left: 1px solid rgba(112, 112, 112, 0.27);
                background-color: rgba(0, 0, 0, 0.27);

                &.light {
                    background-color: transparent;
                    border-color: transparent;

                    span {
                        color: #404040;
                    }
                }

                span {
                    line-height: formatPX_1920(30px);
                    font-size: formatPX_1920(24px);
                    color: #fff;
                    transform: rotate(90deg);
                }
            }
        }

        .warpper {
            z-index: 10;
            width: 100%;
            height: 100vh;
            font-size: formatPX_1920(48px);
            color: #EEEDE8;
            background-color: #303030;
            transform: translate3d(100%, 0, 0);
            transition: all 0.5s;

            &.active {
                transform: translate3d(0, 0, 0);
            }

            .close {
                position: absolute;
                right: formatPX_1920(160px);
                top: formatPX_1920(60px);
                width: formatPX_1920(60px);
                height: formatPX_1920(60px);
            }

            .item_wrap {
                position: absolute;
                left: formatPX_1920(360px);
                top: formatPX_1920(220px);

                .item {
                    position: relative;
                    width: formatPX_1920(800px);
                    height: formatPX_1920(82px);
                    line-height: formatPX_1920(82px);
                    margin-top: formatPX_1920(80px);

                    &:hover {
                        font-size: formatPX_1920(68px);

                        .cn {
                            display: block;
                        }
                        .icon {
                            display: block;
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    .cn {
                        display: none;
                        line-height: formatPX_1920(46px);
                        font-size: formatPX_1920(24px);
                        padding-left: formatPX_1920(24px);
                        border-left: formatPX_1920(4px) solid #EEEDE8;
                        margin-left: formatPX_1920(24px);
                    }

                    .icon {
                        display: none;
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: formatPX_1920(80px);
                        height: formatPX_1920(80px);
                        transition: transform 0.3s;
                    }
                }
            }

            .all_series {
                position: absolute;
                right: formatPX_1920(360px);
                bottom: 20%;
            }
        }

    }
</style>