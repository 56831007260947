import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import axios from 'axios'

Vue.prototype.$ajax = axios

import {
    mobileCosBaseUrl,
    pcCosBaseUrl,
    baseCosBaseUrl
} from "./config/evn"
Vue.prototype.$mobileCosBaseUrl = mobileCosBaseUrl
Vue.prototype.$pcCosBaseUrl = pcCosBaseUrl
Vue.prototype.$baseCosBaseUrl = baseCosBaseUrl

import VueAwesomeSwiper from 'vue-awesome-swiper'

//引入样式
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */ )

//懒加载lazyload
import VueLazyload from 'vue-lazyload';
// with options
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('@/assets/imgerror.jpg'),
    loading: require("@/assets/timg.gif"),
    attempt: 1
})

Vue.config.productionTip = false;

// 设置当前终端平台
let platform
if ((navigator.userAgent.match(/(phone|baipad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    console.log('mobile')
    platform = 'mobile'
} else {
    console.log('pc')
    platform = 'pc'
}
store.commit('setPlatform', platform)

// router.beforeEach((to, from, next) => {
//     console.log("main router.beforeEach");

//     // if (!store.state.network) {
//     //     console.log('断网了！')
//     //     store.commit('SETSHOWNOTNET', true)
//     //     return
//     // }

//     // 默认'/'为移动端首页
//     if (store.state.platform == 'pc' && to.name.indexOf('m-') != -1) {
//         console.log('修改访问平台')

//         next({
//             name: to.name.substring(2),
//             params: to.params
//         })
//     } else if (store.state.platform == 'mobile' && to.name.indexOf('m-') == -1) {
//         console.log('修改访问平台')
//         next({
//             name: 'm-' + to.name,
//             params: to.params
//         })
//     } else {
//         console.log('不用修改访问平台')

//         if (to.meta.content) {
//             let head = document.getElementsByTagName('head');
//             let meta = document.createElement('meta');
//             document.querySelector('meta[name="Keywords"]').setAttribute('content', to.meta.content.keywords)
//             document.querySelector('meta[name="Description"]').setAttribute('content', to.meta.content.description)
//             meta.content = to.meta.content;
//             head[0].appendChild(meta)
//         }
//         if (to.meta.title) {
//             document.title = to.meta.title;
//         }
//         //设置当前系统页面（路径）[重定向需要重新改]
//         store.commit('setCurPageName', to.name);
//         next()
//     }
// });

router.beforeEach((to, from, next) => {

    // if (!store.state.network) {
    //     console.log('断网了！')
    //     store.commit('SETSHOWNOTNET', true)
    //     return
    // }
    if (!navigator.webdriver) {
        let platform = store.state.platform
        if (to.path === '/') {
            if (platform === 'pc') {
                next({
                    name: 'home'
                })
            } else {
                next({
                    name: 'm-home'
                })
            }
        } else if (platform === 'pc' && to.name.indexOf('m-') !== -1) {
            // console.log('修改访问平台')
    
            next({
                name: to.name.substring(2),
                query: to.query
            })
        } else if (platform === 'mobile' && to.name.indexOf('m-') === -1) {
            // console.log('修改访问平台')
    
            // detail页面只有pc端才有，需要特殊处理
            if (to.name === 'detail') {
                next({
                    name: 'm-product',
                    query: to.query
                })
            } else {
                next({
                    name: 'm-'+to.name,
                    query: to.query
                })
            }
        } else {
            // console.log('不用修改访问平台')
    
            if (to.meta.content) {
                let head = document.getElementsByTagName('head');
                let meta = document.createElement('meta');
                document.querySelector('meta[name="Keywords"]').setAttribute('content', to.meta.content.keywords)
                document.querySelector('meta[name="Description"]').setAttribute('content', to.meta.content.description)
                meta.content = to.meta.content;
                head[0].appendChild(meta)
            }
            if (to.meta.title) {
                document.title = to.meta.title;
            }
            //设置当前系统页面（路径）[重定向需要重新改]
            store.commit('setCurPageName', to.name);
            next()
        }
    } else {
        if (to.meta.content) {
            let head = document.getElementsByTagName('head');
            let meta = document.createElement('meta');
            document.querySelector('meta[name="Keywords"]').setAttribute('content', to.meta.content.keywords)
            document.querySelector('meta[name="Description"]').setAttribute('content', to.meta.content.description)
            meta.content = to.meta.content;
            head[0].appendChild(meta)
        }
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        //设置当前系统页面（路径）[重定向需要重新改]
        store.commit('setCurPageName', to.name);
        next()
    }
});

// px2rem 自适应
// import 'lib-flexible'
import '@/utils/flexible.js'

//
// import Router from 'vue-router'
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
// return routerPush.call(this, location).catch(error=> error)
// }

new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event')) // 预渲染
    }
}).$mount("#app");