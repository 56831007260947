<template>
    <div class="home_page">
        <custom-nav-bar :showBack="false"></custom-nav-bar>
        <img class="logo" src="@/assets/img_pc/logo.png" alt="">
        <div class="rotation_wrap">
            <!-- <swiper :options="swiperOption" ref="rotationSwiper" @slideChangeTransitionStart="slideChangeStart"
                class="rotation_content w_h_100">
                <swiper-slide class="rotation_item one">
                    <img :src="rotationList[1]" class="w_h_100" alt="">
                    <div class="text_wrap two flex_col flex_align_start">
                        <span class="en HurmeGeometricSans3_Black"
                            :style="{transform: `translate3d(0,${rotationTextTop}px,0)`}">addictivemusic</span>
                        <span class="cn SourceHanSansCN_Bold"
                            :style="{transform: `translate3d(0,${rotationTextTop}px,0)`}">让人沉醉上瘾的高品质音乐</span>
                    </div>
                    <a :href="productsData[2] && productsData[2].href" target="_blank">
                        <img class="more_btn click_cursor" src="@/assets/img_pc/tag_08.png" alt="">
                    </a>
                </swiper-slide>
                <swiper-slide class="rotation_item two">
                    <img :src="rotationList[0]" class="w_h_100" alt="">
                    <div class="text_wrap one flex_col flex_align_end">
                        <span class="en HurmeGeometricSans3_Black"
                            :style="{transform: `translate3d(0,${rotationTextTop}px,0)`}">addictivemusic</span>
                        <span class="cn SourceHanSansCN_Bold"
                            :style="{transform: `translate3d(0,${rotationTextTop}px,0)`}">让人沉醉上瘾的高品质音乐</span>
                    </div>
                    <a :href="productsData[3] && productsData[3].href" target="_blank">
                        <img class="more_btn click_cursor" src="@/assets/img_pc/tag_08.png" alt="">
                    </a>
                </swiper-slide>
            </swiper>
            <div class="next_btn click_cursor" @click="handleNext">
                <img v-show="activedIndex !== rotationList.length-1" class="rotate absolute_all_0 w_h_100"
                    src="@/assets/img_pc/prev_actived.png" alt="">
                <img v-show="activedIndex === rotationList.length-1" class="absolute_all_0 w_h_100"
                    src="@/assets/img_pc/next.png" alt="">
            </div>
            <div class="prev_btn click_cursor" @click="handlePrev">
                <img v-show="activedIndex !== 0" class="absolute_all_0 w_h_100" src="@/assets/img_pc/prev_actived.png"
                    alt="">
                <img v-show="activedIndex === 0" class="rotate absolute_all_0 w_h_100" src="@/assets/img_pc/next.png"
                    alt="">
            </div> -->
            <div class="rotation_item">
                <img :src="rotationList[0]" class="w_h_100" alt="">
                <span class="text_1 HurmeGeometricSans3_Black font_size_60 absolute_l_center">life style</span>
               `<div class="text_2 absolute_l_center">
                    <img src="@/assets/img_pc/tag_09.png" alt="">
               </div>
                <a :href="productsData[2] && productsData[2].href" target="_blank">
                    <img class="more_btn click_cursor absolute_l_center" src="@/assets/img_pc/tag_10.png" alt="">
                </a>
            </div>
        </div>
        <div class="idea_wrap flex_col flex_align_start">
            <img class="title_tag" src="@/assets/img_pc/tag_01.png" alt="">
            <span class="title_text HurmeGeometricSans3_SemiBold">Interpretation<br />of brand concept</span>
            <div class="flex_align_center">
                <img class="home_idea"
                    src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_idea.png" alt="">
                <img class="home_idea_tag" src="@/assets/img_pc/tag_02.png" alt="">
            </div>
            <p class="idea_content">
                乐毒(LEDO)矢志追求高品质听音效果，致力于设计出让人上瘾，疗伤奋进的优质音响数码设备。凭借对完美音质的深度理解，对精湛工艺的坚持传承，对视觉美感的享受统一，以及对高精技术研发的执着追求，打造一个符合时代潮流兼具性能与体验的音响数码潮酷品牌，沉浸音乐，<span>唤醒激情与灵魂。</span>
            </p>
        </div>
        <div class="more_wrap flex_col">
            <span class="title_text">LEDO Pursue high<br />quality listening effect</span>
            <div class="flex_align_end">
                <img class="tag" src="@/assets/img_pc/tag_03.png" alt="">
                <div class="more_tag_wrap flex_align_center flex_justify_end click_cursor" @click="$router.push({name: 'products'})">
                    <span>探索更多品牌产品</span>
                    <img class="more_tag" src="@/assets/img_pc/tag_04.png" alt="">
                </div>
            </div>
        </div>
        <div class="new_wrap">
            <div class="title_wrap flex_justify_between">
                <div class="flex_col flex_align_start">
                    <div class="flex_align_end">
                        <h1 class="HurmeGeometricSans3_Black">LEDO</h1>
                        <span>当季新品</span>
                    </div>
                    <h3 class="HurmeGeometricSans3_SemiBold">New Season</h3>
                </div>
                <img class="new_tag" src="@/assets/img_pc/tag_05.png" alt="">
            </div>
            <video controls :src="$baseCosBaseUrl+'ledoVideo.mp4'" :poster="$baseCosBaseUrl+'poster.png'"></video>
        </div>
        <scroll-bar></scroll-bar>
        <div class="wrapper img_1_wrap">
            <img class="absolute_all_0 w_h_100"
                src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_img_1.jpg" alt="">
            <img class="tag click_cursor" src="@/assets/img_pc/tag_07.png"
                @click="$router.push({name: 'products',params: {id: 'product-3'}})" alt="">
        </div>
        <div class="wrapper img_2_wrap">
            <img class="absolute_all_0 w_h_100"
                src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_img_2.jpg" alt="">
            <div class="detail flex_col flex_align_center">
                <span class="name SourceHanSansCN_Bold">{{productsData[1] && productsData[1].homeText}}</span>
                <a :href="productsData[1] && productsData[1].href" class="img_wrap click_cursor" target="_blank">
                    <img class="click_cursor"
                        src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_img_2_product.png"
                        alt="">
                </a>
                <span class="price HurmeGeometricSans3_Bold">￥{{productsData[1] && productsData[1].price}}</span>
            </div>
        </div>
        <div class="wrapper img_3_wrap flex_align_center">
            <div class="detail flex_col flex_align_center">
                <span class="name HurmeGeometricSans3_SemiBold">{{productsData[2] && productsData[2].en}} <span
                        class="PingFang_Blod">{{productsData[2] && productsData[2].cn}}</span></span>
                <a :href="productsData[2] && productsData[2].href" class="img_wrap click_cursor" target="_blank">
                    <img class="click_cursor"
                        src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_img_3_product.png"
                        alt="">
                </a>
                <span class="intro SourceHanSansCN_Medium">{{productsData[2] && productsData[2].secondTitle}}</span>
                <span class="price HurmeGeometricSans3_Bold">￥{{productsData[2] && productsData[2].price}}</span>
            </div>
            <img class="home_img_3"
                src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_img_3.jpg" alt="">
        </div>
        <div class="earphone_wrap" id="earphone-wrap">
            <div class="temp_wrap" ref="headsetWrap">
                <img class="earphone_text" ref="headsetText1" src="@/assets/img_pc/earphone_text.png" alt="">
                <div class="text_wrap flex_col flex_align_start" ref="headsetText2">
                    <span>高清通话 持久续航</span>
                    <span>毒牙设计[拒绝千篇一律的视觉享受]撕裂灵魂</span>
                    <span>的高清音质，对于人声与流行乐行云流水</span>
                    <span>般的演绎过耳不忘。</span>
                </div>
                <div class="img_wrap" ref="imgWrap">
                    <img class="earphone" ref="headset" src="@/assets/img_pc/earphone.png" alt="">
                    <img class="sonic_wave" ref="sonicWave" src="@/assets/img_pc/sonic_wave.gif" alt="">
                </div>
            </div>
        </div>
        <div class="wrapper img_4_wrap">
            <img class="absolute_all_0 w_h_100"
                src="https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_img_4.jpg" alt="">
            <img class="more_tag click_cursor" src="@/assets/img_pc/tag_06.png"
                @click="$router.push({name: 'products',params: {id: 'product-4'}})" alt="">
        </div>
        <scroll-bar></scroll-bar>
        <foot></foot>
        <to-top></to-top>
    </div>
</template>

<script>
    import customNavBar from "@/components/pc/custom-nav-bar.vue";
    import scrollBar from "@/components/pc/scroll-bar.vue"
    import foot from "@/components/pc/foot.vue"
    import toTop from "@/components/pc/to-top.vue"

    import sonicWaveGif from '@/assets/img_pc/sonic_wave.gif'

    import { mapState } from 'vuex'

    export default {
        name: 'home',
        data() {
            return {
                swiperOption: {
                    clickable: true,
                    direction: 'vertical'
                },
                activedIndex: 0,
                rotationList: [
                    'https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_rotation_01.jpg',
                    // 'https://ledo-ledu-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/home_rotation_02.png'
                ],
                rotationTextTop: 0,
                clientHeight: 0, // 浏览器可见区域高度
                earphoneTop: 0, // 记录耳机容器距离顶部的高度
                isShowGif: false, // 是否显示了gif
            }
        },
        mounted() {
            this.earphoneTop = document.getElementById('earphone-wrap').offsetTop
            this.clientHeight = window.innerHeight || document.documentElement.clientHeight
            document.addEventListener('scroll', this.bigHeadset)
            window.addEventListener('resize', this.onResize)
        },
        destroyed() {
            document.removeEventListener('scroll', this.bigHeadset)
            window.removeEventListener('resize', this.onResize)
        },
        computed: {
            ...mapState([
                'productsData'
            ]),
            rotationSwiper() {
                return this.$refs.rotationSwiper.swiper
            }
        },
        methods: {
            slideChangeStart(e) {
                this.activedIndex = this.rotationSwiper.activeIndex
            },
            handleNext() {
                this.rotationSwiper.slideNext()
            },
            handlePrev() {
                this.rotationSwiper.slidePrev()
            },
            bigHeadset() {
                let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                this.rotationTextTop = -scrollTop / 3
                let earphoneTop = this.earphoneTop
                let step = (2000 - this.clientHeight) / 2 // 耳机容器固定高度2000px,将全部动画分成两部分完成
                let difference = scrollTop - earphoneTop // 页面滚动距离与耳机容器高度的差值
                if (difference < 0) {
                    this.$refs.headsetWrap.style.position = 'absolute'
                    this.$refs.headsetWrap.style.top = '0'
                    this.$refs.headsetWrap.style.bottom = 'auto'
                } else if (difference >= 0 && difference <= step) {
                    this.$refs.headsetWrap.style.position = 'fixed'
                    let value = 1 - (0.25 * (difference / step))
                    this.$refs.headset.style.transform = `translate3d(-50%, -50%, 0) scale(${value})`
                    this.$refs.headsetText1.style.bottom = '0'
                    this.$refs.headsetText2.style.bottom = '0'
                } else if (difference > step && difference <= step * 2) {
                    this.$refs.headsetWrap.style.position = 'fixed'
                    let value = 10 * ((difference - step) / step)
                    let leftValue = 30 * ((difference - step) / step)
                    let topValue = 8 * ((difference - step) / step)
                    let text1TopValue = 65 * ((difference - step) / step)
                    let text2TopValue = 55 * ((difference - step) / step)
                    this.$refs.headset.style.transform = `translate3d(-50%, -50%, 0) scale(0.75) rotate(${value}deg)`
                    this.$refs.sonicWave.style.display = 'none'
                    if (this.isShowGif) {
                        this.isShowGif = false
                        this.$refs.sonicWave.src = ''
                    }
                    this.$refs.imgWrap.style.left = `${leftValue}%`
                    this.$refs.imgWrap.style.top = `${topValue}%`
                    this.$refs.headsetText1.style.bottom = `${text1TopValue}%`
                    this.$refs.headsetText2.style.bottom = `${text2TopValue}%`
                } else {
                    this.$refs.headsetWrap.style.position = 'absolute'
                    this.$refs.headsetWrap.style.top = 'auto'
                    this.$refs.headsetWrap.style.bottom = '0'
                    this.$refs.imgWrap.style.left = '30%'
                    this.$refs.imgWrap.style.top = '8%'
                    this.$refs.headset.style.transform = `translate3d(-50%, -50%, 0) scale(0.75) rotate(10deg)`
                    if (!this.isShowGif) {
                        this.isShowGif = true
                        this.$refs.sonicWave.src = sonicWaveGif
                    }
                    this.$refs.sonicWave.style.display = 'block'
                    this.$refs.headsetText1.style.bottom = '65%'
                    this.$refs.headsetText2.style.bottom = '55%'
                }
            },
            onResize() {
                setTimeout(() => {
                    this.earphoneTop = document.getElementById('earphone-wrap').offsetTop
                    this.clientHeight = window.innerHeight || document.documentElement.clientHeight
                    this.bigHeadset()
                }, 500)
            }
        },
        components: {
            customNavBar,
            scrollBar,
            foot,
            toTop
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/style/mixin.scss";

    .home_page {
        position: relative;
        background-color: #111111;

        .logo {
            position: absolute;
            left: formatPX_1920(360px);
            top: formatPX_1920(25px);
            z-index: 9;
            width: formatPX_1920(124px);
            height: formatPX_1920(38px);
        }

        .rotation_wrap {
            position: relative;

            .rotation_content {
                height: formatPX_1920(1080px);
            }
            .rotation_item {
                    height: formatPX_1920(1080px);
                    overflow: hidden;
                    .text_1 {
                        bottom: formatPX_1920(200px);
                        font-size: formatPX_1920(60px);
                        color: #00FF00;
                    }

                    .text_2 {
                        bottom: formatPX_1920(142px);
                        img {
                            width: formatPX_1920(260px);
                            height: formatPX_1920(25px);
                        }
                    }
                    .more_btn {
                        bottom: formatPX_1920(70px);
                        width: formatPX_1920(141px);
                        height: formatPX_1920(50px);
                    }

                    /* &.one {
                        .more_btn {
                            position: absolute;
                            left: formatPX_1920(360px);
                            bottom: formatPX_1920(293px);
                            z-index: 9;
                            width: formatPX_1920(141px);
                            height: formatPX_1920(50px);
                        }
                    }

                    &.two {
                        .more_btn {
                            position: absolute;
                            right: formatPX_1920(225px);
                            bottom: formatPX_1920(241px);
                            z-index: 9;
                            width: formatPX_1920(141px);
                            height: formatPX_1920(50px);
                        }
                    } */
                }

            /* .next_btn,
            .prev_btn {
                position: absolute;
                left: formatPX_1920(200px);
                width: formatPX_1920(50px);
                height: formatPX_1920(50px);

                .rotate {
                    transform: rotate(180deg);
                }
            }

            .next_btn {
                bottom: formatPX_1920(430px);
            }

            .prev_btn {
                top: formatPX_1920(430px);
            }

            .text_wrap {
                position: absolute;

                span {
                    transition: transform 0.3s ease;
                }

                &.one {
                    right: formatPX_1920(225px);
                    top: formatPX_1920(475px);

                    .en {
                        line-height: formatPX_1920(110px);
                        font-size: formatPX_1920(90px);
                        margin-bottom: formatPX_1920(30px);
                    }

                    .cn {
                        font-size: formatPX_1920(38px);
                        letter-spacing: formatPX_1920(14px);
                    }
                }

                &.two {
                    left: formatPX_1920(360px);
                    top: formatPX_1920(360px);

                    .en {
                        line-height: formatPX_1920(136px);
                        font-size: formatPX_1920(102px);
                        margin-bottom: formatPX_1920(60px);
                    }

                    .cn {
                        line-height: formatPX_1920(51px);
                        font-size: formatPX_1920(38px);
                        letter-spacing: formatPX_1920(12px);
                    }
                }
            } */
        }

        .idea_wrap {
            color: #EEEDE8;
            padding: formatPX_1920(190px) 0 formatPX_1920(110px) formatPX_1920(360px);

            .title_tag {
                width: formatPX_1920(397px);
                height: formatPX_1920(67px);
            }

            .title_text {
                line-height: formatPX_1920(34px);
                font-size: formatPX_1920(24px);
                margin: formatPX_1920(20px) 0 formatPX_1920(90px);
            }

            .home_idea {
                width: formatPX_1920(1086px);
                height: formatPX_1920(647px);
                margin-right: formatPX_1920(32px);
            }

            .home_idea_tag {
                width: formatPX_1920(82px);
                height: formatPX_1920(647px);
            }

            .idea_content {
                width: formatPX_1920(1094px);
                line-height: formatPX_1920(60px);
                font-size: formatPX_1920(24px);
                text-indent: 2em;
                margin-top: formatPX_1920(110px);

                span {
                    color: #04C104;
                }
            }
        }

        .more_wrap {
            padding-left: formatPX_1920(360px);

            .title_text {
                line-height: formatPX_1920(30px);
                font-size: formatPX_1920(18px);
                color: #DFDFDB;
            }

            .tag {
                width: formatPX_1920(60px);
                height: formatPX_1920(16px);
                margin-right: formatPX_1920(35px);
            }

            .more_tag_wrap {
                width: formatPX_1920(1105px);
                border-bottom: 1px solid #404040;

                span {
                    color: #fff;
                }

                img {
                    width: formatPX_1920(70px);
                    height: formatPX_1920(70px);
                    margin-left: formatPX_1920(30px);
                }
            }
        }

        .new_wrap {
            height: formatPX_1920(1513px);
            padding-top: formatPX_1920(280px);

            .title_wrap {
                padding: 0 formatPX_1920(360px);
                margin-bottom: formatPX_1920(174px);

                h1 {
                    line-height: formatPX_1920(110px);
                    font-size: formatPX_1920(90px);
                    color: #00FF00;
                    margin-right: formatPX_1920(8px);
                }

                span {
                    line-height: formatPX_1920(76px);
                    font-size: formatPX_1920(24px);
                    color: #00FF00;
                }

                h3 {
                    line-height: formatPX_1920(75px);
                    font-size: formatPX_1920(62px);
                    color: #404040;
                }

                .new_tag {
                    width: formatPX_1920(596px);
                    height: formatPX_1920(143px);
                }
            }

            video {
                width: formatPX_1920(1200px);
                margin: 0 auto;
            }
        }

        .wrapper {
            width: 100%;
            height: formatPX_1920(1080px);

            .img_wrap {
                position: relative;
                border: 1px solid #00FF00;
                border-radius: formatPX_1920(40px);
                overflow: hidden;

                &::before {
                    position: absolute;
                    left: -1px;
                    right: -1px;
                    top: -1px;
                    bottom: -1px;
                    z-index: -1;
                    filter: blur(5px);
                    content: '';
                }
            }
        }

        .img_1_wrap {
            position: relative;

            .tag {
                position: absolute;
                left: formatPX_1920(180px);
                bottom: formatPX_1920(407px);
                z-index: 1;
                width: formatPX_1920(150px);
                height: formatPX_1920(36px);
            }
        }

        .img_2_wrap {
            position: relative;

            .detail {
                position: absolute;
                right: formatPX_1920(210px);
                top: 0;
                height: 100%;
                z-index: 1;
                padding-top: formatPX_1920(155px);

                .img_wrap {
                    width: formatPX_1920(538px);
                    height: formatPX_1920(682px);
                    margin: formatPX_1920(48px) 0 formatPX_1920(30px);

                    &::before {
                        background-color: rgba(0, 0, 0, .08);
                    }
                }

                img {
                    width: formatPX_1920(538px);
                    height: formatPX_1920(682px);
                }

                .name {
                    font-size: formatPX_1920(47px);
                }

                .price {
                    line-height: formatPX_1920(48px);
                    font-size: formatPX_1920(36px);
                }
            }
        }

        .img_3_wrap {
            background: linear-gradient(360deg, #00FF00 0%, #AEECAE 35%, #E9E9E9 100%);

            .detail {
                width: 50%;
                height: 100%;
                padding-top: formatPX_1920(134px);

                .img_wrap {
                    position: relative;
                    z-index: 1;
                    width: formatPX_1920(489px);
                    height: formatPX_1920(620px);
                    margin: formatPX_1920(30px) 0 formatPX_1920(24px);

                    &::before {
                        background-color: rgba(0, 0, 0, .08);
                    }
                }

                img {
                    width: formatPX_1920(489px);
                    height: formatPX_1920(620px);
                }

                .name {
                    line-height: formatPX_1920(58px);
                    font-size: formatPX_1920(48px);

                    span:last-child {
                        font-size: formatPX_1920(40px);
                    }
                }

                .intro {
                    font-size: formatPX_1920(30px);
                }

                .price {
                    line-height: formatPX_1920(48px);
                    font-size: formatPX_1920(36px);
                    margin-top: formatPX_1920(24px);
                }
            }

            .home_img_3 {
                width: 50%;
                height: 100%;
            }
        }

        .earphone_wrap {
            position: relative;
            height: 2000PX;
            overflow: hidden;



            .temp_wrap {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                background-image: url('~@/assets/img_pc/earphone_bg.png');
                background-size: contain;
                overflow: hidden;

                .img_wrap {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;

                    .sonic_wave {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(formatPX_1920(-410px), formatPX_1920(-345px), 0);
                        display: none;
                        width: formatPX_1920(323px);
                        height: formatPX_1920(286px);
                    }
                }


            }

            .earphone_text {
                position: absolute;
                left: formatPX_1920(356px);
                bottom: 0;
                width: formatPX_1920(473px);
                height: formatPX_1920(67px);
                transform: translate3d(0, 100%, 0);
            }

            .text_wrap {
                position: absolute;
                left: formatPX_1920(360px);
                bottom: 0;
                align-items: flex-start;
                font-family: PingFang-Medium;
                font-size: formatPX_1920(18px);
                line-height: formatPX_1920(48px);
                color: #DFDFDB;
                transform: translate3d(0, 100%, 0);

                span:first-child {
                    color: #fff;
                    font-size: formatPX_1920(30px);
                }
            }

            .earphone {
                position: absolute;
                left: 50%;
                top: 50%;
                width: formatPX_1920(600px);
                height: formatPX_1920(600px);
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .img_4_wrap {
            position: relative;

            .more_tag {
                position: absolute;
                right: formatPX_1920(260px);
                top: formatPX_1920(518px);
                z-index: 1;
                width: formatPX_1920(150px);
                height: formatPX_1920(36px);
            }
        }
    }
</style>