<template>
    <div class="m_nav_bar flex_align_center flex_justify_between">
        <slot>
            <img class="go_back" v-if="showBack" src="@/assets/img_mobile/go_back.png" @click="$router.go(-1)" alt="">
        </slot>
        <!-- <img class="logo" v-show="!showBack" src="@/assets/img_mobile/logo.png" alt=""> -->
        <img class="menu" v-if="showMenu" src="@/assets/img_mobile/menu.png" @click="isShowMenu=true" alt="">
        <div class="mask fixed_l_t w_h_100" @click="isShowMenu=false" v-show="isShowMenu"></div>
        <div class="menu_wrap HurmeGeometricSans3_SemiBold flex_col flex_align_start fixed_r_t" v-show="isShowMenu"
            @click.stop>
            <div class="item" @click="gotoProducts(`product-${item.id}`)" v-for="(item, index) of productsData"
                :key="index">{{item.en}}</div>
            <div class="all_series" @click="$router.push('/m-ategories')">All series</div>
            <img class="close" src="@/assets/img_mobile/close.png" @click="isShowMenu=false" alt="">
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'm-customHead',
        props: {
            showBack: {
                type: Boolean,
                default: true
            },
            showMenu: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isShowMenu: false
            }
        },
        computed: {
            ...mapState(['curPageName', 'productsData'])
        },
        methods: {
            gotoProducts(id) {
                if (this.curPageName == 'm-products') {
                    let scrollHeight = document.getElementById(id).offsetTop
                    window.scrollTo(0, scrollHeight)
                } else {
                    this.$router.push({
                        name: 'm-products',
                        params: {
                            id
                        }
                    })
                }
                this.isShowMenu = false
            }
        },
    }
</script>

<style lang="scss" scoped>
    .m_nav_bar {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 11;
        width: 100%;
        height: 88px;
        color: #EEEDE8;
        padding: 0 38px;

        .menu {
            position: fixed;
            right: 38px;
            top: 26px;
            width: 48px;
            height: 36px;
        }

        .go_back {
            width: 60px;
            height: 60px;
        }

        .menu_wrap {
            width: 488px;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.92);
            padding: 225px 0 0 60px;

            .mask {
                z-index: -1;
            }

            .item {
                height: 37px;
                line-height: 37px;
                font-size: 30px;
                margin-top: 90px;

                &:first-child {
                    margin-top: 0;
                }
            }

            .all_series {
                position: absolute;
                right: 30px;
                bottom: 200px;
                font-size: 38px;
            }

            .close {
                position: absolute;
                right: 30px;
                top: 22px;
                width: 48px;
                height: 48px;
            }
        }
    }
</style>