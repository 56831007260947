<template>
    <div class="m-scroll-bar">
        <div class="scroll_tag_wrap flex_align_center scroll">
            <img class="scroll_bar_tag" src="@/assets/img_mobile/scroll_bar.png" alt="">
            <img class="scroll_bar_tag" src="@/assets/img_mobile/scroll_bar_reverse.png" alt="">
            <img class="scroll_bar_tag" src="@/assets/img_mobile/scroll_bar.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
    @import "@/style/mixin.scss";

    @keyframes scroll {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-2092px, 0, 0);
        }
    }
    .scroll {
        animation: scroll 8s linear infinite;
    }

    .m-scroll-bar {
        position: relative;
        width: 100%;
        height: 60px;

        .scroll_tag_wrap {
            position: absolute;
            left: 0;
            top: 14px;
            transform: translate3d(0, 0, 0);
            .scroll_bar_tag {
                width: 1046px;
                height: 32px;
            }
        }

    }
</style>