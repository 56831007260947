<template>
    <div id="app" :class="platform =='pc'?'pc':'mobile'">
        <router-view />
        <!-- <keep-alive> -->
        <!-- 需要缓存的视图组件 -->
        <!-- <router-view v-if="$route.meta.keepAlive"></router-view>
         </keep-alive> -->
        <!-- 不需要缓存的视图组件 -->
        <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { productsDataCosUrl } from '@/config/evn.js'

    export default {
        data() {
            return {}
        },
        created() {
            // 获取产品数据并保存在store中
            this.$ajax.get(productsDataCosUrl).then((res) => {
                let productsData = res.data.productsData
                this.$store.commit('setProductsData', productsData)
            }, (err) => {
                console.log(err)
            })
        },
        computed: {
            ...mapState(['platform'])
        }
    }
</script>

<style lang="scss">
    /* 样式重置 */
    @import '~@/style/reset.css';
    @import '@/style/mixin.scss';
    /* 引入自定义公共样式 */
    /* @import '@/style/common.scss'; */
    @import '~@/style/common.css';

    /* // 引入字体文件 */
    @import '~@/fonts/fonts.css';

    /* 自定义可点击时鼠标样式 */
    .click_cursor {
        cursor: pointer;
    }

    #app {
        font-family: PingFang-Medium, Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #111111;
        font-weight: normal;
        overflow: hidden;
    }

    .pc {
        font-size: formatPX_1920(28px);
    }

    .mobile {
        font-size: 28px;
    }
</style>