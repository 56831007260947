import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        curPageName: '', // 当前页面
        platform: '', // 当前终端平台 mobile：移动端；pc:电脑端
        productsData: [] // 所有产品数据
    },
    mutations: {
        // 设置当前页面
        setCurPageName(state, curPageName) {
            state.curPageName = curPageName
        },
        // 设置当前终端平台
        setPlatform(state, platform) {
            state.platform = platform
        },
        // 设置所有产品数据
        setProductsData(state, productsData) {
            state.productsData = productsData
        }
    },
    actions: {},
    modules: {},
});