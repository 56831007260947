import Vue from "vue";
import VueRouter from "vue-router";
import Index from '@/views/index.vue'
import Home from "@/views/pc/Home.vue";
import MHome from '@/views/mobile/m-Home.vue'


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: Index,
        meta: {
            title: "乐毒官网",
            content: {
                keywords: "LEDO, 乐毒, 乐毒官网",
                description: "欢迎进入乐毒官网，了解更多乐毒相关。。。",
            }
        }
    },
    // pc端首页
    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            title: "乐毒官网",
            content: {
                keywords: "LEDO, 乐毒, 乐毒官网",
                description: "欢迎进入乐毒官网，了解更多乐毒相关。。。",
            }
        }
    },
    // mobile端首页
    {
        path: "/m-home",
        name: "m-home",
        component: MHome,
        meta: {
            title: "乐毒官网",
            content: {
                keywords: "LEDO, 乐毒, 乐毒官网",
                description: "欢迎进入乐毒官网，了解更多乐毒相关。。。",
            }
        }
    },
    // pc端页面
    {
        path: "/products",
        name: "products",
        component: () => import('@/views/pc/Products.vue'),
        meta: {
            title: "乐毒产品",
            content: {
                keywords: "乐毒产品",
                description: "欢迎进入乐毒产品，了解更多乐毒相关。。。",
            }
        }
    }, {
        path: "/ategories",
        name: "ategories",
        component: () => import('@/views/pc/Ategories.vue'),
        meta: {
            title: "乐毒类别",
            content: {
                keywords: "乐毒类别",
                description: "欢迎进入乐毒类别，了解更多乐毒相关。。。",
            }
        }
    },
    // mobile端页面
    {
        path: "/m-products",
        name: "m-products",
        component: () => import('@/views/mobile/m-Products.vue'),
        meta: {
            title: "乐毒产品",
            content: {
                keywords: "乐毒产品",
                description: "欢迎进入乐毒产品，了解更多乐毒相关。。。",
            }
        }
    }, {
        path: "/m-ategories",
        name: "m-ategories",
        component: () => import('@/views/mobile/m-Ategories.vue'),
        meta: {
            title: "乐毒类别",
            content: {
                keywords: "乐毒类别",
                description: "欢迎进入乐毒类别，了解更多乐毒相关。。。",
            }
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
});

export default router;